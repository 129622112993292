import { CountryData, Leaderbord } from 'type';

const endpoint =
  process.env.NODE_ENV === 'production' ? '' : 'https://minecraft-rta-ranking-local.s3.ap-northeast-1.amazonaws.com';

/**
 * URLのパスの各成分をエンコードする
 */
const encodeURIForPath = (path: string) =>
  path
    .split('/')
    .map((frag) => encodeURIComponent(frag))
    .join('/');

export const fetchCountries = async (_: string, category: string, versionRange: string) => {
  const path = encodeURIForPath(`/leaderboard/${category}/${versionRange}/country_list.json`);
  const res = await fetch(`${endpoint}${path}`);
  return (await res.json()) as CountryData[];
};

export const fetchLeaderboard = async (_: string, category: string, versionRange: string, country: string) => {
  const path = encodeURIForPath(`/leaderboard/${category}/${versionRange}/items/${country}.json`);
  const res = await fetch(`${endpoint}${path}`);
  return (await res.json()) as Leaderbord;
};
