type FlagData = {
  name: string;
  name_jp: string;
  emoji: string;
};

export const FLAGS: { [key: string]: FlagData } = {
  Iceland: { name: 'Iceland', name_jp: 'アイスランド', emoji: '🇮🇸' },
  Ireland: { name: 'Ireland', name_jp: 'アイルランド', emoji: '🇮🇪' },
  'Ascension Island': { name: 'Ascension Island', name_jp: 'アセンション島', emoji: '🇦🇨' },
  Azerbaijan: { name: 'Azerbaijan', name_jp: 'アゼルバイジャン', emoji: '🇦🇿' },
  Afghanistan: { name: 'Afghanistan', name_jp: 'アフガニスタン', emoji: '🇦🇫' },
  'United States': { name: 'United States', name_jp: 'アメリカ', emoji: '🇺🇸' },
  'American Samoa': { name: 'American Samoa', name_jp: 'アメリカ領サモア', emoji: '🇦🇸' },
  'Virgin Islands, U.S.': { name: 'Virgin Islands, U.S.', name_jp: 'アメリカ領ヴァージン諸島', emoji: '🇻🇮' },
  'United Arab Emirates': { name: 'United Arab Emirates', name_jp: 'アラブ首長国連邦', emoji: '🇦🇪' },
  Algeria: { name: 'Algeria', name_jp: 'アルジェリア', emoji: '🇩🇿' },
  Argentina: { name: 'Argentina', name_jp: 'アルゼンチン', emoji: '🇦🇷' },
  Aruba: { name: 'Aruba', name_jp: 'アルバ', emoji: '🇦🇼' },
  Albania: { name: 'Albania', name_jp: 'アルバニア', emoji: '🇦🇱' },
  Armenia: { name: 'Armenia', name_jp: 'アルメニア', emoji: '🇦🇲' },
  Anguilla: { name: 'Anguilla', name_jp: 'アンギラ', emoji: '🇦🇮' },
  Angola: { name: 'Angola', name_jp: 'アンゴラ', emoji: '🇦🇴' },
  'Antigua and Barbuda': { name: 'Antigua and Barbuda', name_jp: 'アンティグア・バーブーダ', emoji: '🇦🇬' },
  Andorra: { name: 'Andorra', name_jp: 'アンドラ', emoji: '🇦🇩' },
  Yemen: { name: 'Yemen', name_jp: 'イエメン', emoji: '🇾🇪' },
  'United Kingdom': { name: 'United Kingdom', name_jp: 'イギリス', emoji: '🇬🇧' },
  'British Indian Ocean Territory': {
    name: 'British Indian Ocean Territory',
    name_jp: 'イギリス領インド洋地域',
    emoji: '🇮🇴',
  },
  'Virgin Islands, British': { name: 'Virgin Islands, British', name_jp: 'イギリス領ヴァージン諸島', emoji: '🇻🇬' },
  Israel: { name: 'Israel', name_jp: 'イスラエル', emoji: '🇮🇱' },
  Italy: { name: 'Italy', name_jp: 'イタリア', emoji: '🇮🇹' },
  Iraq: { name: 'Iraq', name_jp: 'イラク', emoji: '🇮🇶' },
  Iran: { name: 'Iran', name_jp: 'イラン', emoji: '🇮🇷' },
  India: { name: 'India', name_jp: 'インド', emoji: '🇮🇳' },
  Indonesia: { name: 'Indonesia', name_jp: 'インドネシア', emoji: '🇮🇩' },
  'Wallis And Futuna': { name: 'Wallis And Futuna', name_jp: 'ウォリス・フツナ', emoji: '🇼🇫' },
  Uganda: { name: 'Uganda', name_jp: 'ウガンダ', emoji: '🇺🇬' },
  Ukraine: { name: 'Ukraine', name_jp: 'ウクライナ', emoji: '🇺🇦' },
  Uzbekistan: { name: 'Uzbekistan', name_jp: 'ウズベキスタン', emoji: '🇺🇿' },
  Uruguay: { name: 'Uruguay', name_jp: 'ウルグアイ', emoji: '🇺🇾' },
  Ecuador: { name: 'Ecuador', name_jp: 'エクアドル', emoji: '🇪🇨' },
  Egypt: { name: 'Egypt', name_jp: 'エジプト', emoji: '🇪🇬' },
  Estonia: { name: 'Estonia', name_jp: 'エストニア', emoji: '🇪🇪' },
  Ethiopia: { name: 'Ethiopia', name_jp: 'エチオピア', emoji: '🇪🇹' },
  Eritrea: { name: 'Eritrea', name_jp: 'エリトリア', emoji: '🇪🇷' },
  'El Salvador': { name: 'El Salvador', name_jp: 'エルサルバドル', emoji: '🇸🇻' },
  Oman: { name: 'Oman', name_jp: 'オマーン', emoji: '🇴🇲' },
  Netherlands: { name: 'Netherlands', name_jp: 'オランダ', emoji: '🇳🇱' },
  Australia: { name: 'Australia', name_jp: 'オーストラリア', emoji: '🇦🇺' },
  Austria: { name: 'Austria', name_jp: 'オーストリア', emoji: '🇦🇹' },
  'Åland Islands': { name: 'Åland Islands', name_jp: 'オーランド諸島', emoji: '🇦🇽' },
  Kazakhstan: { name: 'Kazakhstan', name_jp: 'カザフスタン', emoji: '🇰🇿' },
  Qatar: { name: 'Qatar', name_jp: 'カタール', emoji: '🇶🇦' },
  Canada: { name: 'Canada', name_jp: 'カナダ', emoji: '🇨🇦' },
  'Canary Islands': { name: 'Canary Islands', name_jp: 'カナリア諸島', emoji: '🇮🇨' },
  Cameroon: { name: 'Cameroon', name_jp: 'カメルーン', emoji: '🇨🇲' },
  Cambodia: { name: 'Cambodia', name_jp: 'カンボジア', emoji: '🇰🇭' },
  'Cape Verde': { name: 'Cape Verde', name_jp: 'カーボヴェルデ', emoji: '🇨🇻' },
  Guyana: { name: 'Guyana', name_jp: 'ガイアナ', emoji: '🇬🇾' },
  Gabonese: { name: 'Gabonese', name_jp: 'ガボン', emoji: '🇬🇦' },
  Gambia: { name: 'Gambia', name_jp: 'ガンビア', emoji: '🇬🇲' },
  Ghana: { name: 'Ghana', name_jp: 'ガーナ', emoji: '🇬🇭' },
  Guernsey: { name: 'Guernsey', name_jp: 'ガーンジー', emoji: '🇬🇬' },
  Cyprus: { name: 'Cyprus', name_jp: 'キプロス', emoji: '🇨🇾' },
  Curacao: { name: 'Curacao', name_jp: 'キュラソー島', emoji: '🇨🇼' },
  Cuba: { name: 'Cuba', name_jp: 'キューバ', emoji: '🇨🇺' },
  Kiribati: { name: 'Kiribati', name_jp: 'キリバス', emoji: '🇰🇮' },
  Kyrgyz: { name: 'Kyrgyz', name_jp: 'キルギス', emoji: '🇰🇬' },
  Guinea: { name: 'Guinea', name_jp: 'ギニア', emoji: '🇬🇳' },
  'Guinea-Bissau': { name: 'Guinea-Bissau', name_jp: 'ギニアビサウ', emoji: '🇬🇼' },
  Greece: { name: 'Greece', name_jp: 'ギリシャ', emoji: '🇬🇷' },
  Kuwait: { name: 'Kuwait', name_jp: 'クウェート', emoji: '🇰🇼' },
  'Cook Islands': { name: 'Cook Islands', name_jp: 'クック諸島', emoji: '🇨🇰' },
  'Christmas Island': { name: 'Christmas Island', name_jp: 'クリスマス島', emoji: '🇨🇽' },
  'Clipperton Island': { name: 'Clipperton Island', name_jp: 'クリッパートン島', emoji: '🇨🇵' },
  Croatia: { name: 'Croatia', name_jp: 'クロアチア', emoji: '🇭🇷' },
  Guatemala: { name: 'Guatemala', name_jp: 'グアテマラ', emoji: '🇬🇹' },
  Guadeloupe: { name: 'Guadeloupe', name_jp: 'グアドループ', emoji: '🇬🇵' },
  Guam: { name: 'Guam', name_jp: 'グアム', emoji: '🇬🇺' },
  Greenland: { name: 'Greenland', name_jp: 'グリーンランド', emoji: '🇬🇱' },
  Grenada: { name: 'Grenada', name_jp: 'グレナダ', emoji: '🇬🇩' },
  'Cayman Islands': { name: 'Cayman Islands', name_jp: 'ケイマン諸島', emoji: '🇰🇾' },
  Kenya: { name: 'Kenya', name_jp: 'ケニア', emoji: '🇰🇪' },
  'Cocos (Keeling) Islands': { name: 'Cocos (Keeling) Islands', name_jp: 'ココス（キーリング）諸島', emoji: '🇨🇨' },
  'Costa Rica': { name: 'Costa Rica', name_jp: 'コスタリカ', emoji: '🇨🇷' },
  Kosovo: { name: 'Kosovo', name_jp: 'コソボ', emoji: '🇽🇰' },
  Comoros: { name: 'Comoros', name_jp: 'コモロ', emoji: '🇰🇲' },
  Colombia: { name: 'Colombia', name_jp: 'コロンビア', emoji: '🇨🇴' },
  'Republic of the Congo': { name: 'Republic of the Congo', name_jp: 'コンゴ共和国', emoji: '🇨🇬' },
  'Democratic Republic of the Congo': {
    name: 'Democratic Republic of the Congo',
    name_jp: 'コンゴ民主共和国',
    emoji: '🇨🇩',
  },
  'Cote d&#8217;Ivoire': { name: 'Cote d&#8217;Ivoire', name_jp: 'コートジボワール', emoji: '🇨🇮' },
  'Saudi Arabia': { name: 'Saudi Arabia', name_jp: 'サウジアラビア', emoji: '🇸🇦' },
  Samoa: { name: 'Samoa', name_jp: 'サモア', emoji: '🇼🇸' },
  'Sao Tome and Principe': { name: 'Sao Tome and Principe', name_jp: 'サントメ・プリンシペ', emoji: '🇸🇹' },
  'St. Pierre and Miquelon': { name: 'St. Pierre and Miquelon', name_jp: 'サンピエール島・ミクロン島', emoji: '🇵🇲' },
  'San Marino': { name: 'San Marino', name_jp: 'サンマリノ', emoji: '🇸🇲' },
  'Saint Barthélemy': { name: 'Saint Barthélemy', name_jp: 'サン・バルテルミー', emoji: '🇧🇱' },
  'Saint Martin': { name: 'Saint Martin', name_jp: 'サン・マルタン島', emoji: '🇲🇫' },
  Zambia: { name: 'Zambia', name_jp: 'ザンビア', emoji: '🇿🇲' },
  'Sierra Leone': { name: 'Sierra Leone', name_jp: 'シエラレオネ', emoji: '🇸🇱' },
  Syria: { name: 'Syria', name_jp: 'シリア', emoji: '🇸🇾' },
  Singapore: { name: 'Singapore', name_jp: 'シンガポール', emoji: '🇸🇬' },
  'Sint Maarten': { name: 'Sint Maarten', name_jp: 'シント・マールテン', emoji: '🇸🇽' },
  Djibouti: { name: 'Djibouti', name_jp: 'ジブチ', emoji: '🇩🇯' },
  Gibraltar: { name: 'Gibraltar', name_jp: 'ジブラルタル', emoji: '🇬🇮' },
  Jamaica: { name: 'Jamaica', name_jp: 'ジャマイカ', emoji: '🇯🇲' },
  Jersey: { name: 'Jersey', name_jp: 'ジャージー', emoji: '🇯🇪' },
  Georgia: { name: 'Georgia', name_jp: 'ジョージア', emoji: '🇬🇪' },
  Zimbabwe: { name: 'Zimbabwe', name_jp: 'ジンバブエ', emoji: '🇿🇼' },
  Switzerland: { name: 'Switzerland', name_jp: 'スイス', emoji: '🇨🇭' },
  Sweden: { name: 'Sweden', name_jp: 'スウェーデン', emoji: '🇸🇪' },
  Spain: { name: 'Spain', name_jp: 'スペイン', emoji: '🇪🇸' },
  Suriname: { name: 'Suriname', name_jp: 'スリナム', emoji: '🇸🇷' },
  'Sri Lanka': { name: 'Sri Lanka', name_jp: 'スリランカ', emoji: '🇱🇰' },
  Slovakia: { name: 'Slovakia', name_jp: 'スロバキア', emoji: '🇸🇰' },
  Slovenia: { name: 'Slovenia', name_jp: 'スロベニア', emoji: '🇸🇮' },
  Swaziland: { name: 'Swaziland', name_jp: 'スワジランド', emoji: '🇸🇿' },
  'Svalbard and Jan Mayen': {
    name: 'Svalbard and Jan Mayen',
    name_jp: 'スヴァールバル諸島およびヤンマイエン島',
    emoji: '🇸🇯',
  },
  Sudan: { name: 'Sudan', name_jp: 'スーダン', emoji: '🇸🇩' },
  'Ceuta and Melilla': { name: 'Ceuta and Melilla', name_jp: 'セウタとメリリャ', emoji: '🇪🇦' },
  Senegal: { name: 'Senegal', name_jp: 'セネガル', emoji: '🇸🇳' },
  Serbia: { name: 'Serbia', name_jp: 'セルビア', emoji: '🇷🇸' },
  'Saint Christopher and Nevis': {
    name: 'Saint Christopher and Nevis',
    name_jp: 'セントクリストファー・ネイビス',
    emoji: '🇰🇳',
  },
  'Saint Vincent and the Grenadines': {
    name: 'Saint Vincent and the Grenadines',
    name_jp: 'セントビンセント・グレナディーン',
    emoji: '🇻🇨',
  },
  'Saint Helena': { name: 'Saint Helena', name_jp: 'セントヘレナ', emoji: '🇸🇭' },
  'Saint Lucia': { name: 'Saint Lucia', name_jp: 'セントルシア', emoji: '🇱🇨' },
  Seychelles: { name: 'Seychelles', name_jp: 'セーシェル', emoji: '🇸🇨' },
  Somalia: { name: 'Somalia', name_jp: 'ソマリア', emoji: '🇸🇴' },
  'Solomon Islands': { name: 'Solomon Islands', name_jp: 'ソロモン諸島', emoji: '🇸🇧' },
  Thailand: { name: 'Thailand', name_jp: 'タイ', emoji: '🇹🇭' },
  Tajikistan: { name: 'Tajikistan', name_jp: 'タジキスタン', emoji: '🇹🇯' },
  Tanzania: { name: 'Tanzania', name_jp: 'タンザニア', emoji: '🇹🇿' },
  'Turks And Caicos': { name: 'Turks And Caicos', name_jp: 'タークス・カイコス諸島', emoji: '🇹🇨' },
  'Czech Republic': { name: 'Czech Republic', name_jp: 'チェコ', emoji: '🇨🇿' },
  Chad: { name: 'Chad', name_jp: 'チャド', emoji: '🇹🇩' },
  Tunisia: { name: 'Tunisia', name_jp: 'チュニジア', emoji: '🇹🇳' },
  Chile: { name: 'Chile', name_jp: 'チリ', emoji: '🇨🇱' },
  Tuvalu: { name: 'Tuvalu', name_jp: 'ツバル', emoji: '🇹🇻' },
  'Diego Garcia': { name: 'Diego Garcia', name_jp: 'ディエゴガルシア島', emoji: '🇩🇬' },
  Denmark: { name: 'Denmark', name_jp: 'デンマーク', emoji: '🇩🇰' },
  Tokelau: { name: 'Tokelau', name_jp: 'トケラウ', emoji: '🇹🇰' },
  'Tristan da Cunha': { name: 'Tristan da Cunha', name_jp: 'トリスタンダクーニャ島', emoji: '🇹🇦' },
  'Trinidad and Tobago': { name: 'Trinidad and Tobago', name_jp: 'トリニダード・トバゴ', emoji: '🇹🇹' },
  Turkmenistan: { name: 'Turkmenistan', name_jp: 'トルクメニスタン', emoji: '🇹🇲' },
  Turkey: { name: 'Turkey', name_jp: 'トルコ', emoji: '🇹🇷' },
  Tonga: { name: 'Tonga', name_jp: 'トンガ', emoji: '🇹🇴' },
  Togo: { name: 'Togo', name_jp: 'トーゴ', emoji: '🇹🇬' },
  Germany: { name: 'Germany', name_jp: 'ドイツ', emoji: '🇩🇪' },
  Dominican: { name: 'Dominican', name_jp: 'ドミニカ共和国', emoji: '🇩🇴' },
  Dominica: { name: 'Dominica', name_jp: 'ドミニカ国', emoji: '🇩🇲' },
  Nigeria: { name: 'Nigeria', name_jp: 'ナイジェリア', emoji: '🇳🇬' },
  Nauru: { name: 'Nauru', name_jp: 'ナウル', emoji: '🇳🇷' },
  Namibia: { name: 'Namibia', name_jp: 'ナミビア', emoji: '🇳🇦' },
  Niue: { name: 'Niue', name_jp: 'ニウエ', emoji: '🇳🇺' },
  Nicaragua: { name: 'Nicaragua', name_jp: 'ニカラグア', emoji: '🇳🇮' },
  Niger: { name: 'Niger', name_jp: 'ニジェール', emoji: '🇳🇪' },
  'New Caledonia': { name: 'New Caledonia', name_jp: 'ニューカレドニア', emoji: '🇳🇨' },
  'New Zealand': { name: 'New Zealand', name_jp: 'ニュージーランド', emoji: '🇳🇿' },
  Nepal: { name: 'Nepal', name_jp: 'ネパール', emoji: '🇳🇵' },
  Norway: { name: 'Norway', name_jp: 'ノルウェー', emoji: '🇳🇴' },
  'Norfolk Island': { name: 'Norfolk Island', name_jp: 'ノーフォーク島', emoji: '🇳🇫' },
  Haiti: { name: 'Haiti', name_jp: 'ハイチ', emoji: '🇭🇹' },
  Hungary: { name: 'Hungary', name_jp: 'ハンガリー', emoji: '🇭🇺' },
  'Vatican City': { name: 'Vatican City', name_jp: 'バチカン', emoji: '🇻🇦' },
  Vanuatu: { name: 'Vanuatu', name_jp: 'バヌアツ', emoji: '🇻🇺' },
  Bahamas: { name: 'Bahamas', name_jp: 'バハマ', emoji: '🇧🇸' },
  Bermuda: { name: 'Bermuda', name_jp: 'バミューダ', emoji: '🇧🇲' },
  Barbados: { name: 'Barbados', name_jp: 'バルバドス', emoji: '🇧🇧' },
  Bangladesh: { name: 'Bangladesh', name_jp: 'バングラデシュ', emoji: '🇧🇩' },
  Bahrain: { name: 'Bahrain', name_jp: 'バーレーン', emoji: '🇧🇭' },
  Pakistan: { name: 'Pakistan', name_jp: 'パキスタン', emoji: '🇵🇰' },
  Panama: { name: 'Panama', name_jp: 'パナマ', emoji: '🇵🇦' },
  'Papua New Guinea': { name: 'Papua New Guinea', name_jp: 'パプアニューギニア', emoji: '🇵🇬' },
  Palau: { name: 'Palau', name_jp: 'パラオ', emoji: '🇵🇼' },
  Paraguay: { name: 'Paraguay', name_jp: 'パラグアイ', emoji: '🇵🇾' },
  Palestine: { name: 'Palestine', name_jp: 'パレスチナ', emoji: '🇵🇸' },
  Pitcairn: { name: 'Pitcairn', name_jp: 'ピトケアン', emoji: '🇵🇳' },
  Fiji: { name: 'Fiji', name_jp: 'フィジー', emoji: '🇫🇯' },
  Philippines: { name: 'Philippines', name_jp: 'フィリピン', emoji: '🇵🇭' },
  Finland: { name: 'Finland', name_jp: 'フィンランド', emoji: '🇫🇮' },
  'Faroe Islands': { name: 'Faroe Islands', name_jp: 'フェロー諸島', emoji: '🇫🇴' },
  'Falkland Islands': { name: 'Falkland Islands', name_jp: 'フォークランド諸島', emoji: '🇫🇰' },
  France: { name: 'France', name_jp: 'フランス', emoji: '🇫🇷' },
  'French Guiana': { name: 'French Guiana', name_jp: 'フランス領ギアナ', emoji: '🇬🇫' },
  'French Polynesia': { name: 'French Polynesia', name_jp: 'フランス領ポリネシア', emoji: '🇵🇫' },
  'Terres australes et antarctiques françaises': {
    name: 'Terres australes et antarctiques françaises',
    name_jp: 'フランス領南方・南極地域',
    emoji: '🇹🇫',
  },
  Brazil: { name: 'Brazil', name_jp: 'ブラジル', emoji: '🇧🇷' },
  Bulgaria: { name: 'Bulgaria', name_jp: 'ブルガリア', emoji: '🇧🇬' },
  'Burkina Faso': { name: 'Burkina Faso', name_jp: 'ブルキナファソ', emoji: '🇧🇫' },
  'Brunei Darussalam': { name: 'Brunei Darussalam', name_jp: 'ブルネイ・ダルサラーム', emoji: '🇧🇳' },
  Burundi: { name: 'Burundi', name_jp: 'ブルンジ', emoji: '🇧🇮' },
  Bhutan: { name: 'Bhutan', name_jp: 'ブータン', emoji: '🇧🇹' },
  'Bouvet Island': { name: 'Bouvet Island', name_jp: 'ブーベ島', emoji: '🇧🇻' },
  'Puerto Rico': { name: 'Puerto Rico', name_jp: 'プエルトリコ', emoji: '🇵🇷' },
  'Heard Island and McDonald Islands': {
    name: 'Heard Island and McDonald Islands',
    name_jp: 'ヘアド島・マクドナルド諸島',
    emoji: '🇭🇲',
  },
  Vietnam: { name: 'Vietnam', name_jp: 'ベトナム', emoji: '🇻🇳' },
  Benin: { name: 'Benin', name_jp: 'ベナン', emoji: '🇧🇯' },
  Venezuela: { name: 'Venezuela', name_jp: 'ベネズエラ', emoji: '🇻🇪' },
  Belarus: { name: 'Belarus', name_jp: 'ベラルーシ', emoji: '🇧🇾' },
  Belize: { name: 'Belize', name_jp: 'ベリーズ', emoji: '🇧🇿' },
  Belgium: { name: 'Belgium', name_jp: 'ベルギー', emoji: '🇧🇪' },
  Peru: { name: 'Peru', name_jp: 'ペルー', emoji: '🇵🇪' },
  Honduras: { name: 'Honduras', name_jp: 'ホンジュラス', emoji: '🇭🇳' },
  'Bosnia and Herzegovina': { name: 'Bosnia and Herzegovina', name_jp: 'ボスニア・ヘルツェゴビナ', emoji: '🇧🇦' },
  Botswana: { name: 'Botswana', name_jp: 'ボツワナ', emoji: '🇧🇼' },
  'Bonaire, Saint Eustatius and Saba': {
    name: 'Bonaire, Saint Eustatius and Saba',
    name_jp: 'ボネール、シント・ユースタティウスおよびサバ',
    emoji: '🇧🇶',
  },
  Bolivia: { name: 'Bolivia', name_jp: 'ボリビア', emoji: '🇧🇴' },
  Portugal: { name: 'Portugal', name_jp: 'ポルトガル', emoji: '🇵🇹' },
  Poland: { name: 'Poland', name_jp: 'ポーランド', emoji: '🇵🇱' },
  Macao: { name: 'Macao', name_jp: 'マカオ', emoji: '🇲🇴' },
  Macedonia: { name: 'Macedonia', name_jp: 'マケドニア', emoji: '🇲🇰' },
  Madagascar: { name: 'Madagascar', name_jp: 'マダガスカル', emoji: '🇲🇬' },
  Mayotte: { name: 'Mayotte', name_jp: 'マヨット', emoji: '🇾🇹' },
  Malawi: { name: 'Malawi', name_jp: 'マラウイ', emoji: '🇲🇼' },
  Mali: { name: 'Mali', name_jp: 'マリ', emoji: '🇲🇱' },
  Malta: { name: 'Malta', name_jp: 'マルタ', emoji: '🇲🇹' },
  Martinique: { name: 'Martinique', name_jp: 'マルティニーク島', emoji: '🇲🇶' },
  Malaysia: { name: 'Malaysia', name_jp: 'マレーシア', emoji: '🇲🇾' },
  'Isle of Man': { name: 'Isle of Man', name_jp: 'マン島', emoji: '🇮🇲' },
  Marshall: { name: 'Marshall', name_jp: 'マーシャル諸島', emoji: '🇲🇭' },
  Micronesia: { name: 'Micronesia', name_jp: 'ミクロネシア', emoji: '🇫🇲' },
  Myanmar: { name: 'Myanmar', name_jp: 'ミャンマー', emoji: '🇲🇲' },
  Mexico: { name: 'Mexico', name_jp: 'メキシコ', emoji: '🇲🇽' },
  Mozambique: { name: 'Mozambique', name_jp: 'モザンビーク', emoji: '🇲🇿' },
  Monaco: { name: 'Monaco', name_jp: 'モナコ', emoji: '🇲🇨' },
  Maldives: { name: 'Maldives', name_jp: 'モルディブ', emoji: '🇲🇻' },
  Moldova: { name: 'Moldova', name_jp: 'モルドバ', emoji: '🇲🇩' },
  Morocco: { name: 'Morocco', name_jp: 'モロッコ', emoji: '🇲🇦' },
  Mongolia: { name: 'Mongolia', name_jp: 'モンゴル', emoji: '🇲🇳' },
  Montenegro: { name: 'Montenegro', name_jp: 'モンテネグロ', emoji: '🇲🇪' },
  Montserrat: { name: 'Montserrat', name_jp: 'モントセラト', emoji: '🇲🇸' },
  Mauritius: { name: 'Mauritius', name_jp: 'モーリシャス', emoji: '🇲🇺' },
  Mauritania: { name: 'Mauritania', name_jp: 'モーリタニア', emoji: '🇲🇷' },
  Jordan: { name: 'Jordan', name_jp: 'ヨルダン', emoji: '🇯🇴' },
  Laos: { name: 'Laos', name_jp: 'ラオス', emoji: '🇱🇦' },
  Latvia: { name: 'Latvia', name_jp: 'ラトビア', emoji: '🇱🇻' },
  Lithuania: { name: 'Lithuania', name_jp: 'リトアニア', emoji: '🇱🇹' },
  Liechtenstein: { name: 'Liechtenstein', name_jp: 'リヒテンシュタイン', emoji: '🇱🇮' },
  Libya: { name: 'Libya', name_jp: 'リビア', emoji: '🇱🇾' },
  Liberia: { name: 'Liberia', name_jp: 'リベリア', emoji: '🇱🇷' },
  Luxembourg: { name: 'Luxembourg', name_jp: 'ルクセンブルク', emoji: '🇱🇺' },
  Rwanda: { name: 'Rwanda', name_jp: 'ルワンダ', emoji: '🇷🇼' },
  Romania: { name: 'Romania', name_jp: 'ルーマニア', emoji: '🇷🇴' },
  Lesotho: { name: 'Lesotho', name_jp: 'レソト', emoji: '🇱🇸' },
  Lebanon: { name: 'Lebanon', name_jp: 'レバノン', emoji: '🇱🇧' },
  Reunion: { name: 'Reunion', name_jp: 'レユニオン', emoji: '🇷🇪' },
  Russia: { name: 'Russia', name_jp: 'ロシア', emoji: '🇷🇺' },
  China: { name: 'China', name_jp: '中国', emoji: '🇨🇳' },
  'Central African': { name: 'Central African', name_jp: '中央アフリカ', emoji: '🇨🇫' },
  'Northern Mariana': { name: 'Northern Mariana', name_jp: '北マリアナ諸島', emoji: '🇲🇵' },
  'North Korea': { name: 'North Korea', name_jp: '北朝鮮', emoji: '🇰🇵' },
  'South Africa': { name: 'South Africa', name_jp: '南アフリカ', emoji: '🇿🇦' },
  'South Georgia and the South Sandwich Islands': {
    name: 'South Georgia and the South Sandwich Islands',
    name_jp: '南ジョージア島・南サンドイッチ諸島',
    emoji: '🇬🇸',
  },
  'South Sudan': { name: 'South Sudan', name_jp: '南スーダン', emoji: '🇸🇸' },
  Antarctica: { name: 'Antarctica', name_jp: '南極', emoji: '🇦🇶' },
  Taiwan: { name: 'Taiwan', name_jp: '台湾', emoji: '🇹🇼' },
  'U.S. Minor Outlying Islands': { name: 'US Minor Outlying Is.', name_jp: '合衆国小離島', emoji: '🇺🇲' },
  Japan: { name: 'Japan', name_jp: '日本', emoji: '🇯🇵' },
  'Timor-Leste': { name: 'Timor-Leste', name_jp: '東ティモール', emoji: '🇹🇱' },
  'European Union': { name: 'European Union', name_jp: '欧州連合', emoji: '🇪🇺' },
  'Western Sahara': { name: 'Western Sahara', name_jp: '西サハラ', emoji: '🇪🇭' },
  'Equatorial Guinea': { name: 'Equatorial Guinea', name_jp: '赤道ギニア', emoji: '🇬🇶' },
  'South Korea': { name: 'South Korea', name_jp: '韓国', emoji: '🇰🇷' },
  'Hong Kong': { name: 'Hong Kong', name_jp: '香港', emoji: '🇭🇰' },
  England: { name: 'England', name_jp: 'イングランド', emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿' },
  Wales: { name: 'Wales', name_jp: 'ウェールズ', emoji: '🏴󠁧󠁢󠁷󠁬󠁳󠁿' },
  Scotland: { name: 'Scotland', name_jp: 'スコットランド', emoji: '🏴󠁧󠁢󠁳󠁣󠁴󠁿' },
};
