import React from 'react';

import styled from 'styled-components';

import { ReactComponent as LinkIcon } from 'assets/link.svg';

export type IconType = 'link';

type SVGComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

const icons: { [key in IconType]: SVGComponent } = {
  link: LinkIcon,
};

export type SVGIconProps = {
  className?: string;
  type: IconType;
  size?: number;
};

const SVGIcon: React.FC<SVGIconProps> = ({ className, type, size = 18 }) => {
  const Icon = icons[type];
  return (
    <Wrapper className={className} size={size}>
      <Icon />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ size: number }>`
  display: inline-block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export default SVGIcon;
