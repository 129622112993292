import React from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CombinedCountryLeaderboardItem } from 'type';

import { Flag } from 'components/Flag';
import SVGIcon from 'components/SVGIcon';
import Table from 'components/Table';
import { Time } from 'components/Time';

// 相対時間の有効化
dayjs.extend(relativeTime);

type CombinedCountryLeaderboardProps = {
  className?: string;
  items: CombinedCountryLeaderboardItem[];
  highlightedCountry: string;
  setHighlightedCountry: (countryName: string) => void;
  getLeaderboardPath: (countryName: string) => string;
};

export const CombinedCountryLeaderboard: React.FC<CombinedCountryLeaderboardProps> = ({
  className,
  items,
  highlightedCountry,
  setHighlightedCountry,
  getLeaderboardPath,
}) => {
  const validItemCount = items.filter((item) => item.is_valid).length;
  return (
    <Table className={className}>
      <Table.Head>
        <Table.Row>
          <Table.Header />
          <Table.Header>Rank</Table.Header>
          <Table.Header>Country</Table.Header>
          <Table.Header>Average time</Table.Header>
          <Table.Header>🏃</Table.Header>
          <DateHeader>Last Updated</DateHeader>
          <Table.Header></Table.Header>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {items.map((item, index) => {
          const Row = item.is_valid ? BodyRow : InvalidBodyRow;
          return (
            <Row
              key={`${item.country}`}
              id={item.country}
              highlight={!!highlightedCountry && item.country === highlightedCountry}
              hasBorder={index === validItemCount}
            >
              <AnchorData
                style={{ width: 24 }}
                onClick={() => setHighlightedCountry(item.country !== highlightedCountry ? item.country : '')}
              >
                <AnchorIcon />
              </AnchorData>
              <Table.Data style={{ width: 32 }}>{item.rank !== 0 ? item.rank : '*'}</Table.Data>
              <Table.Data>
                <Flag country={item.country || ''} />
                {item.country}
              </Table.Data>
              <Table.Data>
                <Time value={item.record.in_game_time_t / item.items.length} />
              </Table.Data>
              <Table.Data>{item.player_count}</Table.Data>
              <MobileHiddenDateData>
                <Hover>{dayjs(item.record.date).format('YYYY-MM-DD')}</Hover>
                <HoverOut>{dayjs(item.record.date).fromNow()}</HoverOut>
              </MobileHiddenDateData>
              <Table.Data>
                <StyledLink to={getLeaderboardPath(item.country)}>➡︎</StyledLink>
              </Table.Data>
            </Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

const Hover = styled.div``;
const HoverOut = styled.div``;

const AnchorIcon = styled(SVGIcon).attrs(() => ({ type: 'link', size: 12 }))`
  visibility: hidden;

  margin-left: 12px;
  svg {
    fill: #666;
  }
`;

const BodyRow = styled(Table.BodyRow)<{ hasBorder: boolean }>`
  ${Hover} {
    display: none;
  }
  ${HoverOut} {
    display: block;
  }

  &:hover {
    ${AnchorIcon} {
      visibility: visible;
    }
    ${Hover} {
      display: block;
    }
    ${HoverOut} {
      display: none;
    }
  }

  border-top: ${({ hasBorder }) => (hasBorder ? '1px solid #999' : 'none')};
`;

const InvalidBodyRow = styled(BodyRow)`
  opacity: 0.5;
`;

const AnchorData = styled(Table.Data)`
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const DateHeader = styled(Table.MobileHiddenHeader)`
  position: relative;
`;

const MobileHiddenDateData = styled(Table.MobileHiddenData)`
  min-width: 150px;
`;

const StyledLink = styled(Link)`
  margin-right: 8px;
  color: #999;
`;
