import React from 'react';

import CombinedCountryLeaderboardPage from 'pages/CombinedCountryLeaderboardPage';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';

import LeaderboardPage from './pages/LeaderboardPage';

// productionではconsole.logを潰す
const noop = () => null;
if (process.env.NODE_ENV === 'production') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

// kick off the polyfill!
smoothscroll.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/" component={LeaderboardPage} />
        <Route exact path="/cc" component={CombinedCountryLeaderboardPage} />
        <Redirect to="/" />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);
