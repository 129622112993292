import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Footer: React.FC = () => {
  return (
    <Wrapper>
      <ul>
        <Li>
          <Link to="/">Any% Glitchless</Link>
        </Li>
        <Li>
          <Link to="/cc">Any% Glitchless Combined Country</Link>
        </Li>
      </ul>
      <Copyright>
        data by{' '}
        <a href="https://speedrun.com/" target="_blank" rel="noreferrer">
          speedrun.com
        </a>
      </Copyright>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: auto;
  border-top: 1px solid #666;
  padding: 8px;
  font-weight: 300;
`;

const Li = styled.li``;
const Copyright = styled.div`
  text-align: right;
  color: #999;
  font-size: 12px;

  a {
    color: #999;
    text-decoration: underline;
  }
`;

export default Footer;
