import { createGlobalStyle } from 'styled-components';
import { css } from 'styled-components';
import ResetStyle from 'styled-reset';

const FoundationStyle = css`
  html {
    font-size: 62.5%; /* -> 10px; */
    -webkit-text-size-adjust: 100%; /* スマホでランドスケープ時にフォントが拡大するのを防ぐ */
  }
  body {
    height: 100%;
    display: block;
    margin: 0 auto;
    font-family: 'M PLUS Rounded 1c', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', '游ゴシック', YuGothic,
      'メイリオ', Meiryo, sans-serif;
    line-height: 1.5;
    font-size: 14px;
    font-display: swap;
    background-color: #ffffff;
    color: #333333;
  }

  a {
    text-decoration: none;
    color: #333333;
    cursor: pointer;
  }

  h1 {
    font-weight: bold;
  }

  h2 {
    font-size: 16px;
    font-weight: 700;
  }

  strong {
    font-weight: 700;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cccccc;
    margin: 1em 0;
    padding: 0;
  }

  * {
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;

const GlobalStyle = createGlobalStyle`
  /* reset styles */
  ${ResetStyle}

  /* foundation styles */
  ${FoundationStyle}
`;

export default GlobalStyle;
