import React, { useCallback, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import styled from 'styled-components';
import { LeaderboardItem, Record } from 'type';

import { Flag } from 'components/Flag';
import SVGIcon from 'components/SVGIcon';
import Table from 'components/Table';
import { Time } from 'components/Time';

// 相対時間の有効化
dayjs.extend(relativeTime);

type LeaderboardProps = {
  className?: string;
  country: string;
  items: LeaderboardItem[];
  highlightedUser: string;
  setHighlightedUser: (userName: string) => void;

  // 国別ランキングを表示するか
  showLocalRank: boolean;

  // 詳細表示に関するもの
  showDetail: boolean;
};

type SortKey = keyof Record | 'player' | 'rank' | 'provisional_rank' | 'local_rank' | 'provisional_local_rank';
type SortDirection = 'asc' | 'desc' | null;

const getRankForDisplay = (item: LeaderboardItem, key: 'rank' | 'provisional_rank') => {
  if (key === 'rank') {
    return item.rank || '';
  }

  if (item.provisional_rank === null) {
    return '';
  }

  if (item.provisional_rank === item.rank) {
    return item.provisional_rank;
  }

  return `( ${item.provisional_rank} )`;
};

const getLocalRankForDisplay = (item: LeaderboardItem, key: 'local_rank' | 'provisional_local_rank') => {
  if (key === 'local_rank') {
    return item.local_rank || '';
  }

  if (item.provisional_local_rank === null) {
    return '';
  }

  if (item.provisional_local_rank === item.local_rank) {
    return item.provisional_local_rank;
  }

  return `( ${item.provisional_local_rank} )`;
};

const quote = (value: any) => `'${value}'`;

export const Leaderboard: React.FC<LeaderboardProps> = ({
  className,
  country,
  items,
  highlightedUser,
  setHighlightedUser,
  showLocalRank,
  showDetail,
}) => {
  const [[sortKey, sortDirection], setSortCondition] = useState<[SortKey, SortDirection]>(['in_game_time_t', null]);
  const sortedItems = useMemo(() => {
    if (sortDirection === null) {
      return items;
    }
    const sortDirectionValue = sortDirection === 'asc' ? 1 : -1;

    const getValue = (item: LeaderboardItem, key: SortKey) => {
      switch (key) {
        case 'rank':
        case 'provisional_rank':
        case 'local_rank':
        case 'provisional_local_rank':
          return item[key];
        case 'player':
          return item.player.name;
        default:
          return item.record[key];
      }
    };

    return Array.from(items).sort((a, b) => {
      const aValue = getValue(a, sortKey);
      const bValue = getValue(b, sortKey);
      if (aValue === bValue) {
        return 0;
      } else if (aValue === null) {
        return 1;
      } else if (bValue === null) {
        return -1;
      } else {
        return aValue > bValue ? sortDirectionValue : -sortDirectionValue;
      }
    });
  }, [items, sortKey, sortDirection]);

  const setSortKey = useCallback((sortKey: SortKey) => {
    setSortCondition(([currentSortKey, currentSortDirection]) => {
      // 世界順位の暫定順位は国が"World"の場合だけ表示する
      if (sortKey === 'rank') {
        if (!['rank', 'provisional_rank'].includes(currentSortKey)) {
          return [sortKey, 'asc'];
        }

        let nextSortKey: SortKey | null = null;
        if (currentSortDirection === 'desc') {
          if (currentSortKey === 'rank') {
            nextSortKey = 'provisional_rank';
          } else {
            nextSortKey = 'rank';
          }

          return [nextSortKey, null];
        }

        switch (currentSortDirection) {
          case 'asc':
            return [currentSortKey, 'desc'];
          case null:
            return [currentSortKey, 'asc'];
        }
      }

      if (sortKey === 'local_rank') {
        if (!['local_rank', 'provisional_local_rank'].includes(currentSortKey)) {
          return [sortKey, 'asc'];
        }

        let nextSortKey: SortKey | null = null;
        if (currentSortDirection === 'desc') {
          if (currentSortKey === 'local_rank') {
            nextSortKey = 'provisional_local_rank';
          } else {
            nextSortKey = 'local_rank';
          }

          return [nextSortKey, null];
        }

        switch (currentSortDirection) {
          case 'asc':
            return [currentSortKey, 'desc'];
          case null:
            return [currentSortKey, 'asc'];
        }
      }

      if (currentSortKey !== sortKey) {
        return [sortKey, 'asc'];
      }

      switch (currentSortDirection) {
        case 'asc':
          return [sortKey, 'desc'];
        case 'desc':
          return [sortKey, null];
        case null:
          return [sortKey, 'asc'];
      }
    });
  }, []);

  const [isRelativeDate, setIsRelativeDate] = useState(true);

  return (
    <StyledTable className={className}>
      <thead>
        <SpacerHeaderRow />
        <StickyHeaderRow>
          <Table.Header />
          <Table.Header style={{ minWidth: 70 }} colSpan={showDetail ? 2 : 1}>
            <ClickableHeaderLabel
              onClick={() => setSortKey('rank')}
              sortDirection={['rank', 'provisional_rank'].includes(sortKey) ? sortDirection : null}
              pos={sortKey === 'rank' ? 'before' : 'after'}
            >
              <Flag country="World" />
            </ClickableHeaderLabel>
          </Table.Header>
          {showLocalRank && (
            <Table.Header style={{ minWidth: 70 }} colSpan={showDetail ? 2 : 1}>
              <ClickableHeaderLabel
                onClick={() => setSortKey('local_rank')}
                sortDirection={['local_rank', 'provisional_local_rank'].includes(sortKey) ? sortDirection : null}
                pos={sortKey === 'local_rank' ? 'before' : 'after'}
              >
                <Flag country={country} />
              </ClickableHeaderLabel>
            </Table.Header>
          )}
          <Table.Header>
            <ClickableHeaderLabel
              onClick={() => setSortKey('player')}
              sortDirection={sortKey === 'player' ? sortDirection : null}
            >
              Player
            </ClickableHeaderLabel>
          </Table.Header>
          {showDetail && (
            <Table.Header>
              <ClickableHeaderLabel
                onClick={() => setSortKey('real_time_t')}
                sortDirection={sortKey === 'real_time_t' ? sortDirection : null}
              >
                Real time
              </ClickableHeaderLabel>
            </Table.Header>
          )}
          <Table.Header>
            <ClickableHeaderLabel
              onClick={() => setSortKey('in_game_time_t')}
              sortDirection={sortKey === 'in_game_time_t' ? sortDirection : null}
            >
              In-game time
            </ClickableHeaderLabel>
          </Table.Header>
          {showDetail && (
            <>
              <Table.Header>
                <ClickableHeaderLabel
                  onClick={() => setSortKey('version')}
                  sortDirection={sortKey === 'version' ? sortDirection : null}
                >
                  Version
                </ClickableHeaderLabel>
              </Table.Header>
              <Table.Header>
                <ClickableHeaderLabel
                  onClick={() => setSortKey('difficulty')}
                  sortDirection={sortKey === 'difficulty' ? sortDirection : null}
                >
                  Difficulty
                </ClickableHeaderLabel>
              </Table.Header>
              <Table.Header>
                <ClickableHeaderLabel
                  onClick={() => setSortKey('f3')}
                  sortDirection={sortKey === 'f3' ? sortDirection : null}
                >
                  F3
                </ClickableHeaderLabel>
              </Table.Header>
              <Table.Header>
                <ClickableHeaderLabel
                  onClick={() => setSortKey('mods')}
                  sortDirection={sortKey === 'mods' ? sortDirection : null}
                >
                  Mods
                </ClickableHeaderLabel>
              </Table.Header>
            </>
          )}
          <Table.MobileHiddenHeader forceVisible={showDetail}>
            <ClickableHeaderLabel
              onClick={() => setSortKey('date')}
              sortDirection={sortKey === 'date' ? sortDirection : null}
            >
              Date
              <DateSwith
                onClick={(event) => {
                  setIsRelativeDate((value) => !value);
                  event.stopPropagation();
                }}
              />
            </ClickableHeaderLabel>
          </Table.MobileHiddenHeader>
          {showDetail && (
            <Table.MobileHiddenHeader forceVisible={showDetail}>
              <ClickableHeaderLabel
                onClick={() => setSortKey('verify_date')}
                sortDirection={sortKey === 'verify_date' ? sortDirection : null}
              >
                Verify date
                <DateSwith
                  onClick={(event) => {
                    setIsRelativeDate((value) => !value);
                    event.stopPropagation();
                  }}
                />
              </ClickableHeaderLabel>
            </Table.MobileHiddenHeader>
          )}
          <Table.Header style={{ width: 16 }} />
        </StickyHeaderRow>
      </thead>
      <tbody>
        {sortedItems.map((item) => {
          return (
            <BodyRow
              key={`${item.record.id}`}
              id={item.player.name}
              highlight={!!highlightedUser && item.player.name === highlightedUser}
              unverified={item.rank === null}
            >
              <AnchorData
                style={{ width: 24 }}
                onClick={() => setHighlightedUser(item.player.name !== highlightedUser ? item.player.name : '')}
              >
                <AnchorIcon />
              </AnchorData>
              <>
                {!showDetail ? (
                  <Table.Data>
                    <ExternalLink href={item.record.link}>
                      {getRankForDisplay(item, sortKey.includes('provisional') ? 'provisional_rank' : 'rank') || '*'}
                    </ExternalLink>
                  </Table.Data>
                ) : (
                  <>
                    <Table.Data>
                      <ExternalLink href={item.record.link}>{getRankForDisplay(item, 'rank') || '*'}</ExternalLink>
                    </Table.Data>
                    <Table.Data>{getRankForDisplay(item, 'provisional_rank')}</Table.Data>
                  </>
                )}
              </>
              {showLocalRank && (
                <>
                  {!showDetail ? (
                    <Table.Data>
                      {getLocalRankForDisplay(
                        item,
                        sortKey.includes('provisional') ? 'provisional_local_rank' : 'local_rank',
                      )}
                    </Table.Data>
                  ) : (
                    <>
                      <Table.Data>{getLocalRankForDisplay(item, 'local_rank')}</Table.Data>
                      <Table.Data>{getLocalRankForDisplay(item, 'provisional_local_rank')}</Table.Data>
                    </>
                  )}
                </>
              )}
              <Table.Data>
                {!showLocalRank && <Flag country={item.player.country || ''} />}
                {item.player.name}
              </Table.Data>

              {showDetail && (
                <Table.Data>
                  <Time value={item.record.real_time_t} />
                </Table.Data>
              )}
              <Table.Data>
                <Time value={item.record.in_game_time_t} />
              </Table.Data>
              {showDetail && (
                <>
                  <Table.Data>{item.record.version} </Table.Data>
                  <Table.Data>{item.record.difficulty}</Table.Data>
                  <Table.Data>{item.record.f3}</Table.Data>
                  <Table.Data>{item.record.mods}</Table.Data>
                </>
              )}
              <MobileHiddenDateData forceVisible={showDetail}>
                <Hover>{dayjs(item.record.date).format('YYYY-MM-DD')}</Hover>
                <HoverOut>
                  {isRelativeDate ? dayjs(item.record.date).fromNow() : dayjs(item.record.date).format('YYYY-MM-DD')}
                </HoverOut>
              </MobileHiddenDateData>
              {showDetail && (
                <MobileHiddenDateData forceVisible={showDetail}>
                  {item.record.verify_date !== null ? (
                    <>
                      <Hover>{dayjs(item.record.verify_date).format('YYYY-MM-DD')}</Hover>
                      <HoverOut>
                        {isRelativeDate
                          ? dayjs(item.record.verify_date).fromNow()
                          : dayjs(item.record.verify_date).format('YYYY-MM-DD')}
                      </HoverOut>
                    </>
                  ) : (
                    '---'
                  )}
                </MobileHiddenDateData>
              )}
            </BodyRow>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

const ClickableHeaderLabel: React.FC<{
  onClick: () => void;
  sortDirection?: SortDirection;
  pos?: 'before' | 'after';
}> = ({ onClick, sortDirection, children, pos }) => (
  <ClickableHeaderLabelWrapper onClick={onClick}>
    <ClickableHeaderLabelInner sortDirection={sortDirection} pos={pos}>
      {children}
    </ClickableHeaderLabelInner>
  </ClickableHeaderLabelWrapper>
);

const ClickableHeaderLabelWrapper = styled.div`
  cursor: pointer;
`;
const ClickableHeaderLabelInner = styled.a<{ sortDirection?: SortDirection; pos?: 'before' | 'after' }>`
  text-decoration: none;
  text-decoration-style: dashed;

  position: relative;
  display: inline-block;

  :before {
    content: ${({ sortDirection, pos = 'before' }) =>
      quote(sortDirection == null || pos !== 'before' ? '' : sortDirection === 'asc' ? '▲' : '▼')};
    display: block;
    position: absolute;
    top: 3px;
    left: -12px;
    font-size: 0.75em;
  }

  :after {
    content: ${({ sortDirection, pos = 'before' }) =>
      quote(sortDirection == null || pos !== 'after' ? '' : sortDirection === 'asc' ? '▲' : '▼')};
    display: block;
    position: absolute;
    top: 3px;
    right: -12px;
    font-size: 0.75em;
  }

  ::selection {
    background: transparent;
  }
`;

const Hover = styled.div``;
const HoverOut = styled.div``;

const AnchorIcon = styled(SVGIcon).attrs(() => ({ type: 'link', size: 12 }))`
  visibility: hidden;

  margin-left: 12px;
  svg {
    fill: #666;
  }
`;

const StyledTable = styled(Table)`
  border-collapse: separate;
`;

const SpacerHeaderRow = styled(Table.Row)`
  position: sticky;
  top: 0;
  height: 93px;
  background: #fff;
`;

const StickyHeaderRow = styled(Table.Row)`
  position: sticky;
  top: 93px;
  background: #fff;
  line-height: 2;
  padding: 8px 0;

  th {
    border-bottom: 1px solid #999;
  }
`;

const BodyRow = styled(Table.BodyRow)`
  ${Hover} {
    display: none;
  }
  ${HoverOut} {
    display: block;
  }

  &:hover {
    ${AnchorIcon} {
      visibility: visible;
    }
    ${Hover} {
      display: block;
    }
    ${HoverOut} {
      display: none;
    }
  }
`;

const AnchorData = styled(Table.Data)`
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ExternalLink = styled.a.attrs(() => ({
  target: '_blank',
  rel: 'noreferrer',
}))`
  text-decoration: underline dotted;
  text-underline-offset: 0.2em;
  text-align: center;
`;

const MobileHiddenDateData = styled(Table.MobileHiddenData)`
  min-width: 150px;
`;

const DateSwith = styled.span`
  position: relative;

  :before {
    content: '⇄';
    display: block;
    position: absolute;
    top: -8px;
    right: -24px;
    padding: 4px;
  }
`;
