import React from 'react';

import Spinner from 'react-spinkit';
import styled from 'styled-components';

const LoadingSpinner: React.FC = () => {
  return (
    <SpinnerWrapper>
      <Spinner name="wave" color="#666" fadeIn="half" />
    </SpinnerWrapper>
  );
};

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 240px;
`;

export default React.memo(LoadingSpinner);
