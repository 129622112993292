import React from 'react';

import { FLAGS } from '../utils/flag';

type FlagProps = {
  country?: string;
};

export const Flag: React.FC<FlagProps> = ({ country }) => (
  <>{country === 'World' ? '🌏' : FLAGS[country || '']?.emoji}</>
);
