import {
  Category,
  CombinedCountryLeaderboardItem,
  CombinedType,
  DEFAULT_CATEGORY,
  DEFAULT_COMBINED_TYPES,
  DEFAULT_COUNTRY,
  DEFAULT_RUN_TYPE,
  DEFAULT_VERSION_RANGE,
  LeaderboardItem,
  Leaderbord,
  RunType,
  VersionRange,
} from 'type';

export const getLeaderboardItems = (
  leaderboard: Leaderbord,
  containsUnverigfied: boolean,
  containsSameUserRecord: boolean = true, // 同一ユーザーの複数の記録を含めるか
) => {
  const items = containsUnverigfied
    ? leaderboard.items
    : leaderboard.items.filter((item) => item.record.verify_date !== null);

  const sortedItems = items.sort((a, b) => {
    // 長い時間の記録だとin_game_timeがない場合があるので、その場合は除外
    if (a.record.in_game_time_t !== 0 && b.record.in_game_time_t !== 0) {
      return a.record.in_game_time_t - b.record.in_game_time_t;
    }
    return a.record.real_time_t - b.record.real_time_t;
  });

  if (containsSameUserRecord) {
    return sortedItems;
  }

  const addedUsers = new Set<string>();
  return sortedItems.filter((item) => {
    if (addedUsers.has(item.player.name)) {
      return false;
    }
    addedUsers.add(item.player.name);
    return true;
  });
};

export const getConbinedCountryLeaderboardItems = (
  leaderboard: Leaderbord,
  limit: number, // 各国の何人目の記録までを採用するか
  containsUnverigfied: boolean,
) => {
  // プレイヤーの重複を含まないデータを取得
  const items = getLeaderboardItems(leaderboard, containsUnverigfied, false);

  // 国別の走者数をカウント
  const playerCounts: { [key: string]: number } = {};
  items.forEach((item) => {
    if (!item.player.country) {
      return;
    }
    playerCounts[item.player.country] = (playerCounts[item.player.country] ?? 0) + 1;
  });

  // 国別にローカルランクが閾値以上の記録を集める
  const countryItemsMap: { [key: string]: LeaderboardItem[] } = {};
  items.forEach((item) => {
    // 国がない場合は対象外
    if (!item.player.country) {
      return;
    }

    const countryItems = countryItemsMap[item.player.country] ?? [];

    // ローカルランクが閾値より大きい場合は採用しない
    if (countryItems.length < limit) {
      countryItems.push(item);
      countryItemsMap[item.player.country] = countryItems;
    }
  });

  const countryRecords: CombinedCountryLeaderboardItem[] = Object.entries(countryItemsMap).map(([country, items]) => {
    const totalIngameTime = items.reduce((total, item) => total + item.record.in_game_time_t * 1000, 0) / 1000;
    const totalRealTime = items.reduce((total, item) => total + item.record.real_time_t * 1000, 0) / 1000;
    const latestDate = items.reduce((current, item) => (item.record.date > current ? item.record.date : current), '');
    return {
      rank: 0,
      country,
      player_count: playerCounts[country],
      record: {
        real_time_t: totalRealTime,
        in_game_time_t: totalIngameTime,
        date: latestDate,
      },
      items,
      is_valid: items.length >= limit,
    };
  });

  const sortedCountryRecords = countryRecords.sort((a, b) => {
    // 記録数は多い順(閾値に満ちていない分は下に)
    if (a.items.length === b.items.length) {
      return a.record.in_game_time_t - b.record.in_game_time_t;
    }
    return b.items.length - a.items.length;
  });

  // 順位を振っていく
  sortedCountryRecords.forEach((record, i) => {
    if (record.items.length >= limit) {
      record.rank = i + 1;
    }
  });

  return sortedCountryRecords;
};

export const createLeaderboardPath = (
  category: Category,
  version: VersionRange,
  country: string,
  runType: RunType,
  highlightedUser: string = '',
) => {
  const params = new URLSearchParams();
  if (category !== DEFAULT_CATEGORY) {
    params.set('category', category);
  }
  if (version !== DEFAULT_VERSION_RANGE) {
    params.set('version', version);
  }
  if (country !== DEFAULT_COUNTRY) {
    params.set('country', country);
  }
  if (runType !== DEFAULT_RUN_TYPE) {
    params.set('run', runType);
  }

  let path = params.toString();
  if (path !== '') path = `?${path}`;
  if (highlightedUser !== '') path = `${path}#${highlightedUser}`;

  return `/${path}`;
};

export const createCombinedCountryLeaderboardPath = (
  category: Category,
  version: VersionRange,
  top: CombinedType,
  runType: RunType,
  highlightedCountry: string,
) => {
  const params = new URLSearchParams();
  if (category !== DEFAULT_CATEGORY) {
    params.set('category', category);
  }
  if (version !== DEFAULT_VERSION_RANGE) {
    params.set('version', version);
  }
  if (top !== DEFAULT_COMBINED_TYPES) {
    params.set('top', top.toString());
  }
  if (runType !== DEFAULT_RUN_TYPE) {
    params.set('run', runType);
  }

  let path = params.toString();
  if (path !== '') path = `?${path}`;
  if (highlightedCountry !== '') path = `${path}#${highlightedCountry}`;

  return `/cc/${path}`;
};
