import { useCallback, useLayoutEffect, useState } from 'react';

import ResizeObserver from 'resize-observer-polyfill';

export interface Size {
  width: number;
  height: number;
}

/**
 * ウィンドウサイズが変更された際に変更後のウィンドウサイズを返すカスタムフック
 */
export const useWindowSize = (): Size => {
  const [size, setSize] = useState<Size>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = useCallback(() => {
    setTimeout(() => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 50);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return size;
};

/**
 * ウィンドウサイズが変更された際に指定されたコールバックを実行するカスタムフック
 */
export const useWindowSizeEffect = (callback: (size: Size) => void): void => {
  const size = useWindowSize();
  callback(size);
};

/**
 * 指定要素のサイズが変更された際に変更後のサイズを返すカスタムフック
 */
export const useElementSize = (element: React.RefObject<HTMLElement>): Size => {
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const width = entries[0].contentRect.width;
      const height = entries[0].contentRect.height;
      setSize({ width, height });
    });

    if (element.current) {
      observer.observe(element.current);
    }

    return () => observer.disconnect();
  }, [element]);

  return size;
};

/**
 * 指定要素のサイズが変更された際に指定されたコールバックを実行するカスタムフック
 */
export const useElementSizeEffect = (element: React.RefObject<HTMLElement>, callback: (size: Size) => void): void => {
  const size = useElementSize(element);
  callback(size);
};
