import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';

import { fetchCountries, fetchLeaderboard } from 'api';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import { useLocation, useHistory } from 'react-router-dom';
import Select from 'react-select';
import VisibilitySensor from 'react-visibility-sensor';
import GlobalStyle from 'style';
import styled from 'styled-components';
import useSWRImmutable from 'swr/immutable';
import {
  CATEGORIES,
  Category,
  DEFAULT_CATEGORY,
  DEFAULT_COUNTRY,
  DEFAULT_RUN_TYPE,
  DEFAULT_VERSION_RANGE,
  RunType,
  RUN_TYPES,
  VersionRange,
  VERSION_RANGES,
} from 'type';

import { Flag } from 'components/Flag';
import Footer from 'components/Footer';
import LoadingSpinner from 'components/LoadingSpinner';
import { Switch } from 'components/Switch';
import { createLeaderboardPath, getLeaderboardItems } from 'utils/leaderboard';

import { Leaderboard } from '../components/Leaderboard';
import { FLAGS } from '../utils/flag';

const LeaderboardPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const search = new URLSearchParams(location.search);
  // ランキング対象の国、カテゴリ、バージョン（デフォルト値はクエリパラメータから取得する）
  const [country, setCountry] = useState(search.get('country') || DEFAULT_COUNTRY);
  const [category, setCategory] = useState<Category>(() => {
    const categoryParam = search.get('category') || '';
    if (CATEGORIES.includes(categoryParam as any)) {
      return categoryParam as Category;
    }
    return DEFAULT_CATEGORY;
  });
  const [versionRange, setVersionRange] = useState<VersionRange>(() => {
    const versionParam = search.get('version') || '';
    if (VERSION_RANGES.includes(versionParam as any)) {
      return versionParam as VersionRange;
    }
    return DEFAULT_VERSION_RANGE;
  });

  // 未確認のデータを含めるか
  const [runType, setRunType] = useState<RunType>(search.get('run') === 'cu' ? 'cu' : DEFAULT_RUN_TYPE);
  // ハイライトするユーザーをハッシュから取得する
  const [highlightedUser, setHighlightedUser] = useState(location.hash.replace('#', ''));

  // URLが変更されたらURLから各パラメータを取得する処理
  useEffect(() => {
    const search = new URLSearchParams(location.search);

    const countryParam = search.get('country') || DEFAULT_COUNTRY;
    if (country !== countryParam) {
      setCountry(countryParam);
    }

    const _categoryParam = search.get('category') || '';
    const categoryParam = CATEGORIES.includes(_categoryParam as any) ? (_categoryParam as Category) : DEFAULT_CATEGORY;
    if (category !== categoryParam) {
      setCategory(categoryParam);
    }

    const _versionRangeParam = search.get('version') || '';
    const versionRangeParam = VERSION_RANGES.includes(_versionRangeParam as any)
      ? (_versionRangeParam as VersionRange)
      : DEFAULT_VERSION_RANGE;
    if (versionRange !== versionRangeParam) {
      setVersionRange(versionRangeParam);
    }

    const _runTypeParam = search.get('run') || '';
    const runTypeParam = RUN_TYPES.includes(_runTypeParam as any) ? (_runTypeParam as RunType) : DEFAULT_RUN_TYPE;
    if (runType !== runTypeParam) {
      setRunType(runTypeParam);
    }

    let highlightedUserParam = location.hash.replace('#', '');
    if (highlightedUser !== highlightedUserParam) {
      setHighlightedUser(highlightedUserParam);
    }
    setHighlightedUser(location.hash.replace('#', ''));
  }, [location.search, location.hash, country, category, runType, highlightedUser, versionRange]);

  // 詳細を表示するか
  const [showDetail, setShowDetail] = useState(false);
  const toggleShowDetail = useCallback(() => {
    setShowDetail(!showDetail);
  }, [showDetail]);

  const replaceURL = useCallback(
    (
      newCategory: Category,
      newCountry: string,
      newVersionRange: VersionRange,
      newRunType: RunType,
      newHilightedUser: string,
    ) => {
      let modHilightedUser = newHilightedUser;
      if (
        newCategory !== category ||
        newCountry !== country ||
        newVersionRange !== versionRange ||
        newRunType !== runType
      ) {
        modHilightedUser = '';
      }
      const path = createLeaderboardPath(newCategory, newVersionRange, newCountry, newRunType, modHilightedUser);
      history.push(path);
    },
    [category, country, versionRange, runType, history],
  );

  const { data: countryList = [] } = useSWRImmutable(['fetchCountries', category, versionRange], fetchCountries, {});
  const { data: leaderboard, isValidating: isLoadingLeaderboard } = useSWRImmutable(
    ['fetchLeaderboard', category, versionRange, country],
    fetchLeaderboard,
    {},
  );

  // 未確認のrunを含む/含まないアイテム一覧
  const leaderboardItems = useMemo(
    () => (leaderboard ? getLeaderboardItems(leaderboard, runType === 'cu', true) : []),
    [leaderboard, runType],
  );
  const runnersCount = useMemo(() => new Set(leaderboardItems.map((run) => run.player.name)).size, [leaderboardItems]);

  const countryName = countryList.find((c) => c.key === country)?.name ?? 'World';
  const categoryName = category === 'rsg' ? 'Random Seed' : 'Set Seed';
  const versionName = {
    '1.16+': '1.16+',
    '1.13-1.15': '1.13-1.15',
    '1.9-1.12': '1.9-1.12',
    '1.8': '1.8',
    'pre1.8': 'Pre 1.8',
  }[versionRange];

  const title = `${countryName || 'World'} Ranking - Any% Glitchless - ${categoryName} ${versionName} | Minecraft RTA`;

  // カテゴリ選択用
  const categoryOptions = useMemo(
    () => [
      { value: 'rsg', label: 'Random Seed' },
      { value: 'ssg', label: 'Set Seed' },
    ],
    [],
  );
  const categorySelected = useMemo(
    () => categoryOptions.find((c) => c.value === category),
    [categoryOptions, category],
  );

  // 国選択用
  const countryOptions = useMemo(
    () =>
      countryList
        .sort((a, b) => {
          if (a === b) return 0;
          if (a.key === 'world') return -1;
          if (b.key === 'world') return 1;

          return a.key > b.key ? 1 : -1;
        })
        .map((c) => {
          const mapping = { 'RÃ©union': 'Reunion', 'QuÃ©bec': 'Québec' };
          const name = mapping[c.name] ?? c.name;
          const label = c.key === 'world' ? '🌏 World' : `${FLAGS[name]?.emoji || '　'} ${name}`;
          return { value: c.key, label, name };
        }),
    [countryList],
  );
  const countrySelected = useMemo(() => countryOptions.find((c) => c.value === country), [countryOptions, country]);

  // バージョン選択用
  const versionRangeOptions = useMemo(
    () => [
      { value: '1.16+', label: '1.16+' },
      { value: '1.13-1.15', label: '1.13-1.15' },
      { value: '1.9-1.12', label: '1.9-1.12' },
      { value: '1.8', label: '1.8' },
      { value: 'pre1.8', label: 'Pre 1.8' },
    ],
    [],
  );
  const versionRangeSelected = useMemo(
    () => versionRangeOptions.find((c) => c.value === versionRange),
    [versionRangeOptions, versionRange],
  );

  // 未確認データの含めるかどうかの選択用
  const runTypeOptions = useMemo(
    () => [
      { value: 'vo', label: 'Only Verified Run' },
      { value: 'cu', label: 'Contains Unverified Run' },
    ],
    [],
  );
  const runTypeSelected = useMemo(() => runTypeOptions.find((c) => c.value === runType), [runTypeOptions, runType]);

  // ハンドラ
  const onChangeCountry = useCallback(
    (option: { value: string; label: string }) => {
      if (country !== option.value) {
        replaceURL(category, option.value, versionRange, runType, highlightedUser);
      }
    },
    [country, replaceURL, category, versionRange, runType, highlightedUser],
  );

  const onChangeCategory = useCallback(
    (option: { value: Category; label: string }) => {
      if (category !== option.value) {
        replaceURL(option.value, country, versionRange, runType, highlightedUser);
      }
    },
    [category, replaceURL, country, versionRange, runType, highlightedUser],
  );

  const onChangeVersionRange = useCallback(
    (option: { value: VersionRange; label: string }) => {
      if (versionRange !== option.value) {
        replaceURL(category, country, option.value, runType, highlightedUser);
      }
    },
    [category, replaceURL, country, versionRange, runType, highlightedUser],
  );

  const onChangeRunType = useCallback(
    (option: { value: RunType; label: string }) => {
      if (runType !== option.value) {
        replaceURL(category, country, versionRange, option.value, highlightedUser);
      }
    },
    [runType, replaceURL, category, country, versionRange, highlightedUser],
  );

  const onChangeHighligtedUser = useCallback(
    (userName: string) => {
      if (highlightedUser !== userName) {
        replaceURL(category, country, versionRange, runType, userName);
      }
    },
    [highlightedUser, replaceURL, category, country, versionRange, runType],
  );

  const isHilighted = useRef<boolean>(false);
  // 初回アクセス時のハイライト処理
  useEffect(() => {
    if (leaderboard === undefined || isHilighted.current || !highlightedUser) {
      return;
    }

    isHilighted.current = true;
    setTimeout(() => {
      const element = document.getElementById(highlightedUser);
      if (element === null) {
        return;
      }

      // 対象の要素を画面の上から3/4くらいの位置に来るようにスクロールさせる
      const windowHeight = document.documentElement.clientHeight;
      const elementScrollTop = element.getBoundingClientRect().top;
      const scrollToTop = Math.max(0, elementScrollTop - (windowHeight * 3) / 4);
      window.scrollTo({ top: scrollToTop, behavior: 'smooth' });
    }, 200);
    // 初期のhighlightedUserでのみ動かしたいのであえてdepsには含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaderboard]);

  const [showSmallTitle, setShowSmallTitle] = useState(false);
  const onChangeHeaderIsVisible = useCallback((visible: boolean) => {
    setShowSmallTitle(!visible);
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <GlobalStyle />
      <Wrapper showDetail={showDetail}>
        <HeaderContainer>
          <StyledSwitch mode="spread">
            {({ OpenContents, switchContents }) => (
              <Header>
                <VisibilitySensor onChange={onChangeHeaderIsVisible} partialVisibility={true}>
                  <Title>
                    Minecraft Java Edition
                    <MobileOnly>
                      <br />
                    </MobileOnly>
                    <PCOnly> / </PCOnly>
                    Any% Glitchless - <HeaderLink onClick={switchContents}>{categoryName}</HeaderLink>,{' '}
                    <HeaderLink onClick={switchContents}>{versionName}</HeaderLink>
                    <br />
                    <Flag country={countryName} />
                    &nbsp;
                    <HeaderLink onClick={switchContents}>{countryName}</HeaderLink> Ranking
                  </Title>
                </VisibilitySensor>

                <SwitchWrapper>
                  <OpenContents>
                    <FilterContainer>
                      <FilterItem>
                        <FilterItemLabel>Category</FilterItemLabel>
                        <SelectWrapper>
                          <Select options={categoryOptions} value={categorySelected} onChange={onChangeCategory} />
                        </SelectWrapper>
                      </FilterItem>
                      <FilterItem>
                        <FilterItemLabel>Version</FilterItemLabel>
                        <SelectWrapper>
                          <Select
                            options={versionRangeOptions}
                            value={versionRangeSelected}
                            onChange={onChangeVersionRange}
                          />
                        </SelectWrapper>
                      </FilterItem>
                      <FilterItem>
                        <FilterItemLabel>Country</FilterItemLabel>
                        <SelectWrapper>
                          <Select options={countryOptions} value={countrySelected} onChange={onChangeCountry} />
                        </SelectWrapper>
                      </FilterItem>
                      <FilterItem>
                        <FilterItemLabel>Run Status</FilterItemLabel>
                        <SelectWrapper>
                          <Select options={runTypeOptions} value={runTypeSelected} onChange={onChangeRunType} />
                        </SelectWrapper>
                      </FilterItem>
                    </FilterContainer>
                  </OpenContents>
                </SwitchWrapper>
              </Header>
            )}
          </StyledSwitch>
        </HeaderContainer>
        <StickyHeaderContainer>
          <SmallTitle visible={showSmallTitle}>
            {categoryName}, {versionName} <Flag country={countryName} /> {countryName} Ranking
          </SmallTitle>
          <InfoContainer>
            <RunnersCount>🏃 {isLoadingLeaderboard ? '...' : runnersCount} Runners</RunnersCount>
            <Date onClick={toggleShowDetail}>
              {leaderboard ? (
                <>
                  {showDetail ? <span style={{ fontSize: '0.8em' }}>◀︎ </span> : ''}
                  {dayjs(leaderboard.date).format('YYYY-MM-DD HH:mm')}
                  {!showDetail ? <span style={{ fontSize: '0.8em' }}> ▶︎</span> : ''}
                </>
              ) : (
                ''
              )}
            </Date>
          </InfoContainer>
        </StickyHeaderContainer>
        <LeaderboardContainer>
          {isLoadingLeaderboard || leaderboard === undefined ? (
            <LoadingSpinner />
          ) : (
            <Leaderboard
              country={countrySelected?.name}
              items={leaderboardItems}
              highlightedUser={highlightedUser}
              setHighlightedUser={onChangeHighligtedUser}
              showLocalRank={countrySelected?.value !== 'world'}
              showDetail={showDetail}
            />
          )}
        </LeaderboardContainer>
        <Footer />
      </Wrapper>
    </>
  );
};

export default LeaderboardPage;

const Wrapper = styled.div<{ showDetail: boolean }>`
  --table-width: ${({ showDetail }) => (showDetail ? '1280px' : '640px')};

  width: 100%;
  max-width: var(--table-width);
  min-height: 100vh;
  margin: 0 auto;
  padding: 16px;

  font-family: 'Roboto', sans-serif;
  font-weight: 400;

  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  z-index: 30;
`;

const StyledSwitch = styled(Switch)`
  margin-bottom: 32px;
`;

const Header = styled.div``;
const Title = styled.div`
  position: relative;
  font-size: 18px;
  line-height: 2;
  font-weight: bold;
`;

const StickyHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  background: #fff;

  padding: 8px 16px 0;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -48px;

  z-index: 20;
`;

const SmallTitle = styled.div<{ visible: boolean }>`
  width: 100%;
  font-size: 16px;
  line-height: 3;
  font-weight: bold;
  overflow: visible;
  height: 48px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 200ms ease-in-out;
  white-space: nowrap;
`;

const HeaderLink = styled.span`
  display: inline-block;
  text-decoration: underline dotted;
  text-underline-offset: 0.2em;
  cursor: pointer;
`;

const SwitchWrapper = styled.div`
  position: relative;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 16px 0;
`;

const FilterItem = styled.div`
  max-width: 800px;
  width: 50%;
  display: flex;
  align-items: center;

  @media (max-width: 640px) {
    width: 100%;
  }
`;
const FilterItemLabel = styled.div`
  width: 120px;
`;

const SelectWrapper = styled.div`
  width: 100%;
  margin: 8px 0;

  input,
  select,
  textarea {
    font-size: 16px !important;
  }
`;

const LeaderboardContainer = styled.div`
  margin: -93px 0 65px;

  @media (max-width: 640px) {
    margin-left: -16px;
    margin-right: -16px;
  }

  /* overflow-x: scroll; */
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  z-index: 10;
`;

const InfoContainer = styled.div`
  padding: 8px 16px 16px;
  margin-left: -16px;
  margin-right: -16px;
  background: #fff;
  display: flex;
`;

const RunnersCount = styled.div``;

const Date = styled.div`
  display: inline-block;
  color: #999;
  bottom: 2px;
  font-size: 14px;
  margin-left: auto;
  cursor: pointer;
  min-width: 120px;
`;

const MobileOnly = styled.span`
  display: none;
  @media (max-width: var(--table-width)) {
    display: initial;
  }
`;

const PCOnly = styled.span`
  @media (max-width: var(--table-width)) {
    display: none;
  }
`;
