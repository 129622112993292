import React from 'react';

import styled from 'styled-components';

type TimeProps = {
  className?: string;
  value: number;
};

export const Time: React.FC<TimeProps> = ({ className, value }) => {
  const t = parseTime(value);
  const h = t.h.toString().padStart(2, '0');
  const m = t.m.toString().padStart(2, '0');
  const s = t.s.toString().padStart(2, '0');
  const hms = `${h}:${m}:${s}`;

  // 非表示にする部分フラグ
  let invisibleFlag = true;
  let invisibleText = '';
  let visibleText = '';
  hms.split('').forEach((c) => {
    if (invisibleFlag && (c === '0' || c === ':')) {
      invisibleText += c;
      return;
    }
    visibleText += c;
    invisibleFlag = false;
  });
  return (
    <>
      <Invisible>{invisibleText}</Invisible>
      <Visible>{visibleText}</Visible>
      {t.ms ? <Visible>.{t.ms.toString().padStart(3, '0')}</Visible> : <Invisible>.000</Invisible>}
    </>
  );
};
const Visible = styled.span``;
const Invisible = styled.span`
  visibility: hidden;
`;
const parseTime = (value: number) => {
  return {
    h: Math.floor(value / 3600),
    m: Math.floor(value / 60) % 60,
    s: Math.floor(value) % 60,
    ms: Math.round((value * 1000) % 1000),
  };
};
